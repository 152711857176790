



















import ScheduleCalendar from '@/components/SlotCalendar/ScheduleCalendar.vue';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { Schedule } from '@/models/booked/Schedule';
import { defineComponent, onMounted, PropType, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';
import { useCreatingBooking, currentBooking } from '@/composables/useCreatingBooking';
import { useBookedSchedules } from '@/composables/useBookedResources';
import { formatDateTime, formatDate, formatTime } from '@/utils/date';
import ScheduleCalendarPageHeader from './components/ScheduleCalendarPageHeader.vue';
import ScheduleCalendarConfirmationDrawer from './components/ScheduleCalendarConfirmationDrawer.vue';
import InlineLegend from './components/InlineLegend.vue';
import { PendingReservationResponse } from '@/composables/useBookedResources/types';
import {getReservation} from '@/composables/useBookedResources/useBookedReservations';
import { Slot } from '@/models/booked/Slot';

export default defineComponent({
  components: {
    ScheduleCalendar,
    MemberPage,
    ScheduleCalendarPageHeader,
    ScheduleCalendarConfirmationDrawer,
    InlineLegend,
  },
  name: 'BookingsCalendarPage',
  props: {
    scheduleId: {
      type: String as PropType<string>,
      required: true,
    },

    reservationId: {
      type: String as PropType<string>,
      required: false
    }

  },
  setup(props) {

    console.log('--------- scheduleId: ', props.scheduleId);

    const schedule = ref<Schedule | null>(null);

    const repeats = ref(false);

    const reservation = ref<PendingReservationResponse>();

    const { selected, showSettings, clearSlots } = useCreatingBooking();
    const selectedSlot = ref<Slot>();
    const { getSchedule } = useBookedSchedules();

    const { router } = useRouter();

    const goToLogin = () => {
      router.push({ name: routeNames.auth.login });
    };

    onMounted(async () => {
      if(props.reservationId) {
        reservation.value = await getReservation(props.reservationId);
        //calendarSelection.startSlot = reservation.value.dates[0].start;
        console.log('-------- set param slot here: ', reservation);
        selectedSlot.value = {startDateTime: reservation.value.dates[0].start, endDateTime: reservation.value.dates[0].end, selected: true, duration: reservation.value.duration} as Slot
        console.log('---- set slot: ', selectedSlot);
      }
      schedule.value = await getSchedule(props.scheduleId);
      
    });

    return {
      schedule,
      goToLogin,
      showSettings,
      repeats,
      formatDateTime,
      selected,
      formatDate,
      formatTime,
      reservation,
      clearSlots,
      currentBooking,
      selectedSlot
    };
  },
});
